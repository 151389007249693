html {
  @apply h-full;
}

body {
  @apply h-full bg-black text-white font-doktor-sim;
}

.button {
  @apply inline-flex shadow-[0px_2px_0px_0px_rgb(16,9,107)] rounded-[5px] px-[72px] py-[7px] text-[16px] uppercase font-bold;

  &:hover {
    @apply no-underline;
  }
}

a {
  @apply text-primary;

  &:hover {
    @apply underline;
  }
}
