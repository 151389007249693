@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts/doktor-sim";
@import 'base';

//@import 'widgets/icon-text';
@import 'widgets/accordion';
//@import 'widgets/trustedshops';
//@import 'widgets/tooltip';
