/* Accordion styles */
.accordion {
  @apply overflow-hidden;

  &__item {
    @apply flex gap-[15px] w-full overflow-hidden border-b-[1px] border-white py-[10px];

    input[type=checkbox] {
      @apply hidden;

      &:checked {

        ~.accordion__item__id {
          @apply items-start;
        }

        ~.accordion__item__content {

          >p {
            @apply overflow-visible whitespace-normal;
          }
        }

        ~.accordion__item__label {
          @apply items-start;

          i {
            @apply -rotate-[135deg];
          }
        }
      }
    }

    &__id {
      @apply flex justify-center;
    }

    &__content {
      @apply flex items-center justify-center min-w-[0] transition-all duration-150;

      p {
        @apply overflow-hidden whitespace-nowrap text-ellipsis;
      }
    }

    &__label {
      @apply cursor-pointer text-white flex shrink-0 items-center justify-center h-[inherit] w-[30px];

      i {
        @apply transition-all duration-150 leading-normal;
        @apply border-solid border-white rotate-45 inline-block m-2 p-[3px] border-r-[2px] border-b-[2px] rotate-45; 
      }
    }
  }
}
